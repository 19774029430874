/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { createContext, useState, useEffect } from "react"

type CookieContextType = {
  permissions: {
    accepted: boolean
    strict: boolean
    functional: boolean
    performance: boolean
    marketing: boolean
    // To check if value was read from local storage and if it is, thus, the "final" context and not the default one
    readFromStorage: boolean
  }
  saveContext: (values: {
    accepted: boolean
    strict: boolean
    functional: boolean
    performance: boolean
    marketing: boolean
    readFromStorage: boolean
  }) => void
}

const defaultState = {
  permissions: {
    accepted: false,
    strict: true,
    functional: true,
    performance: true,
    marketing: true,
    readFromStorage: false,
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  saveContext: () => {},
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const CookiesContext = createContext<CookieContextType>(defaultState)

const CookiesProvider: React.FC = ({ children }) => {
  const [permissions, setPermissions] = useState(defaultState.permissions)

  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      !localStorage.getItem("authsiderCookieManager")
    ) {
      localStorage.setItem(
        "authsiderCookieManager",
        JSON.stringify({
          ...permissions,
        })
      )

      setPermissions({ ...permissions, readFromStorage: true })
    } else {
      const settings = JSON.parse(
        localStorage.getItem("authsiderCookieManager") || "{}"
      )

      setPermissions({ ...settings, readFromStorage: true })
    }
  }, [])

  return (
    <CookiesContext.Provider
      value={{
        permissions,
        saveContext: (permissions) => {
          setPermissions(permissions)
          localStorage.setItem(
            "authsiderCookieManager",
            JSON.stringify({
              ...permissions,
            })
          )
        },
      }}
    >
      {children}
    </CookiesContext.Provider>
  )
}

export { CookiesContext, CookiesProvider, defaultState }
