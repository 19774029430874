/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from "react"

import { CookiesProvider } from "./src/context/cookies-context"

export const wrapRootElement = ({ element }) => (
  <CookiesProvider>{element}</CookiesProvider>
)
